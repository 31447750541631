export const isServer = typeof window === 'undefined';
export const isBrowser = !isServer;
export const isPreview = isBrowser && window.location.search.indexOf('preview=1') > -1;
export const forceHeader = isBrowser && window.location.search.indexOf('header=1') > -1;

export const isProd = process.env.NODE_ENV === 'production';
export const isDev = !isProd;

export const INPUT_DEBOUNCE_DELAY = 300;
export const SAVE_DEBOUNCE_DELAY = 1000;
export const REPORT_RESULTS_PAGE_SIZE = 20;
export const BLOG_PAGE_SIZE = 10;
export const EMAILS_PAGE_SIZE = 20;
export const SCROLL_SPEED = 1000;

export const MAX_USER_FILE_SIZE = 209715200; // 200mb
export const humanMaxUserFileSize = '200 МБ';

export const KEEP_DOWNLOAD_LINK_DURATION = 15; // mins

console.d = (...args: Array<any>) =>
    console.log.call(
        console,
        ...[...args].map((a) => {
            try {
                return JSON.parse(JSON.stringify(a));
            } catch (e) {}

            return a;
        })
    );
