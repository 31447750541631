import 'assets/styles/common.css';
import '@webapp/common/assets/styles/colors.css?CSSModulesDisable';
import '@webapp/common/assets/styles/vars.css?CSSModulesDisable';
import '@webapp/common/assets/styles/fonts.css';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import { isBrowser } from '@webapp/common/lib/const';

if (isBrowser) {
    if (document.referrer && !localStorage.getItem('referrer')) {
        localStorage.setItem('referrer', document.referrer);
    }
}

const LandingApp: NextPage<AppProps> = ({ Component, pageProps }) => <Component {...pageProps} />;

// eslint-disable-next-line import/no-default-export
export { LandingApp as default };
